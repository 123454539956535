import * as yup from "yup";
import i18n from "../../services/i18n";
import {lookupService} from "../../services/LookupApiService";
import {LookupResponse} from "../../models/responses/LookupResponse";
import {navigationService} from "../../services/NavigationService";

const i18pcnMin = i18n.t("fieldValidation.pcnMin");
const i18pcnMax = i18n.t("fieldValidation.pcnMax");
const i18pcnReq = i18n.t("fieldValidation.pcnReq");
const i18pcnPrefixError = i18n.t("fieldValidation.pcnPrefixError");
const i18vrmReq = i18n.t("fieldValidation.vrmReq");
const i18vrmInvalid = i18n.t("fieldValidation.vrmInvalid");
const prefixRegex = new RegExp("^" + navigationService.getPrefixByDomain());


export const validateLookup = yup.object().shape({
    pcn: yup.string().uppercase().trim().min(8, i18pcnMin)
        .max(12, i18pcnMax)
        .required(i18pcnReq),
        // .matches(prefixRegex, i18pcnPrefixError),
    vrm: yup.string().uppercase().trim().matches(/^[a-zA-Z0-9- ]*$/, i18vrmInvalid)
        .required(i18vrmReq)
});

export async function doLookup(values: any, navigation: any, myDomain: string, setLookupError: any, setIsLoading: any) {
    try {
        await lookupService.lookup(values.pcn.trim(), values.vrm.trim(), myDomain).then((response: LookupResponse) => {
            setIsLoading(false);
            switch (response.status) {
                case 1: {
                    navigation.navigate("LookupResult", {response});
                    break;
                }
                case 3: {
                    setLookupError('PCN: ' + values.pcn + ' has already been paid.');
                    break;
                }
                case 4: {
                    setLookupError('PCN: ' + values.pcn + ' not found.');
                    break;
                }
                case 5: {
                    setLookupError('Your PCN: ' + values.pcn + ' is now with the bailiff and you should contact the bailiff to make payment.');
                    break;
                }
                case 6: {
                    setLookupError('PCN: ' + values.pcn + ' is on hold.');
                    break;
                }
                case 8: {
                    setLookupError('PCN: ' + values.pcn + ' has been canceled.');
                    break;
                }
                case 10: {
                    setLookupError('PCN: ' + values.pcn + ' is invalid.');
                    break;
                }
                case 11: {
                    setLookupError('PCN: ' + values.pcn + ' can no longer be paid via this service.');
                    break;
                }
                default:
                    setLookupError('There was a problem obtaining PCN information. Please try again later.');
            }
        })
    } catch (e) {
        setIsLoading(false);
        setLookupError('There was a problem obtaining PCN information. Please try again later.')
        console.error("Lookup " + e);
    }
}

export function checkLookupOptions() {
    console.log("Checking for Lookup Options");
}
